import React from 'react';
import { Link } from 'react-router-dom';
import img from '../../assets/yournextdevwhite.png';
import './Navbar.css';
import ReactGA from 'react-ga4'; // Make sure to import ReactGA

const Navbar = () => {
  // Function to handle link clicks
  const handleLinkClick = (pageName) => {
    // Send event to Google Analytics
    ReactGA.event({
      category: 'Navigation',
      action: 'Clicked Navbar Link',
      label: pageName,
    });
  };

  return (
    <nav className="nav-bar">
      <Link
        to="/"
        className="nav-bar__logo"
        onClick={() => handleLinkClick('Home')}
      >
        <img
          src={img}
          alt="Your Next Dev - Home"
          className="nav-bar__logo-image"
        />
      </Link>
      <div className="nav-bar__links">
        <Link
          to="/"
          className="nav-bar__link"
          onClick={() => handleLinkClick('Home')}
        >
          Home
        </Link>
        <Link
          to="/about"
          className="nav-bar__link"
          onClick={() => handleLinkClick('About')}
        >
          About
        </Link>
        <Link
          to="/skills"
          className="nav-bar__link"
          onClick={() => handleLinkClick('Skills')}
        >
          Skills
        </Link>
        <Link
          to="/blog"
          className="nav-bar__link"
          onClick={() => handleLinkClick('Blog')}
        >
          Blog
        </Link>
        <Link
          to="/contact"
          className="nav-bar__link"
          onClick={() => handleLinkClick('Contact')}
        >
          Contact
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
