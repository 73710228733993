import axios from 'axios';

const baseURL =
  'https://brandnewbackend-production.up.railway.app/api/contacts/newmessage';

const newMessageApi = axios.create({
  baseURL,
});

const sendMessage = async (payload) => {
  try {
    console.log('Skicka payload:', payload);
    // skicka meddelande
    const response = await newMessageApi.post('', payload);
    // svar
    return response.data;
  } catch (error) {
    // felhantering
    console.error(
      'Kunde inte skicka meddelande',
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export { sendMessage };
