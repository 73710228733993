// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: 'Sora', sans-serif;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5; /* Ser till så att den håller sig kvar och inte störs ut av annan CCS!!!!!!!!! */
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-container {
  position: relative; 
  
}

.blog-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background-color: transparent;
  box-sizing: border-box;
  max-height: 300px;
 
}

.post-box {
  width: 80%;
  max-width: 800px;
  background-color: transparent;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  list-style-type: none;
  max-height: 700px;
  overflow: auto;
}

.post-box li {
  margin-bottom: 20px;
  color: #f5f0f0;
  max-height: 300px;
}

.post-box h2,
.post-box p {
  margin: 0;
  color: #f8f5f5;
}

.post-box h2 {
  color: #f7f8f8;
}
`, "",{"version":3,"sources":["webpack://./src/components/blog/Blog.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;AACA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,UAAU,EAAE,gFAAgF;EAC5F,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;;AAEpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,WAAW;EACX,6BAA6B;EAC7B,sBAAsB;EACtB,iBAAiB;;AAEnB;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,6BAA6B;EAC7B,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;EACrB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,cAAc;EACd,iBAAiB;AACnB;;AAEA;;EAEE,SAAS;EACT,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["body {\n  font-family: 'Sora', sans-serif;\n}\n.modal {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n  z-index: 5; /* Ser till så att den håller sig kvar och inte störs ut av annan CCS!!!!!!!!! */\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.blog-container {\n  position: relative; \n  \n}\n\n.blog-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  min-height: 100vh;\n  width: 100%;\n  background-color: transparent;\n  box-sizing: border-box;\n  max-height: 300px;\n \n}\n\n.post-box {\n  width: 80%;\n  max-width: 800px;\n  background-color: transparent;\n  padding: 20px;\n  margin-top: 20px;\n  border-radius: 10px;\n  list-style-type: none;\n  max-height: 700px;\n  overflow: auto;\n}\n\n.post-box li {\n  margin-bottom: 20px;\n  color: #f5f0f0;\n  max-height: 300px;\n}\n\n.post-box h2,\n.post-box p {\n  margin: 0;\n  color: #f8f5f5;\n}\n\n.post-box h2 {\n  color: #f7f8f8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
