// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: 'Sora', sans-serif;
}

@media (max-width: 768px) {
  .face-image-container img {
    max-height: 50vh; 
    width: auto; 
  }
}

.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: transparent;
  overflow: hidden;
}

.face-image-container {
  width: 100%; 
  display: flex;
  align-items: center; 
  justify-content: center; 
  margin-bottom: 10px; 
  position: relative;
  z-index: 6;
}

.face-image-container img {
  width: 8vw; 
  height: auto; 
}

.about-text {
  text-align: center;
  color: #f7f5f5;
  font-size: 24;
  max-width: 600px;
  overflow: hidden;
}

.about-text p {
  opacity: 0.9;
  line-height: 1.4;
}`, "",{"version":3,"sources":["webpack://./src/components/about/About.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE;IACE,gBAAgB;IAChB,WAAW;EACb;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,6BAA6B;EAC7B,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,aAAa;EACb,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":["body {\n  font-family: 'Sora', sans-serif;\n}\n\n@media (max-width: 768px) {\n  .face-image-container img {\n    max-height: 50vh; \n    width: auto; \n  }\n}\n\n.about-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  background-color: transparent;\n  overflow: hidden;\n}\n\n.face-image-container {\n  width: 100%; \n  display: flex;\n  align-items: center; \n  justify-content: center; \n  margin-bottom: 10px; \n  position: relative;\n  z-index: 6;\n}\n\n.face-image-container img {\n  width: 8vw; \n  height: auto; \n}\n\n.about-text {\n  text-align: center;\n  color: #f7f5f5;\n  font-size: 24;\n  max-width: 600px;\n  overflow: hidden;\n}\n\n.about-text p {\n  opacity: 0.9;\n  line-height: 1.4;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
