// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video {
  width: 100%;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  bottom: 0;  
  left: 0;
  object-fit: cover;
}

.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;  
  height: 100%; 
  overflow: hidden;
  
}`, "",{"version":3,"sources":["webpack://./src/components/backgroundloop/BackgroundLoop.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,SAAS;EACT,OAAO;EACP,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,gBAAgB;;AAElB","sourcesContent":[".video {\n  width: 100%;\n  height: auto;\n  min-width: 100%;\n  min-height: 100%;\n  position: fixed;\n  bottom: 0;  \n  left: 0;\n  object-fit: cover;\n}\n\n.video-background {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;  \n  height: 100%; \n  overflow: hidden;\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
