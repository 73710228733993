import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import '../home/Home.css';
import faceImage from '../../assets/face.png';
import ReactGA from 'react-ga4';

const TEXT_ANIMATION_DELAY = 2;
const TEXT_ANIMATION_DURATION = 0.8;
const OPACITY_ANIMATION_DURATION = 2;

const textVariants = {
  hidden: { opacity: 0 },
  visible: (i) => ({
    opacity: 1,
    transition: {
      delay: i * TEXT_ANIMATION_DELAY,
      duration: TEXT_ANIMATION_DURATION,
    },
  }),
};

const Home = () => {
  const lastLineWords = ['Your', 'Next', 'Dev.'];

  // google analytics
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/home' });
  }, []);

  return (
    <div className="full-screen-container">
      <div className="full-size-background"></div>
      <div className="centered-flex-container">
        <motion.div
          className="content-card"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: OPACITY_ANIMATION_DURATION }}
        >
          <div className="profile-with-text">
            <img src={faceImage} alt="Profile" className="profile-avatar" />
            <div className="text-container">
              <p className="primary-text">
                <motion.span
                  variants={textVariants}
                  initial="hidden"
                  animate="visible"
                  custom={1}
                >
                  Here´s your shot
                </motion.span>
                <br />
                <motion.span
                  variants={textVariants}
                  initial="hidden"
                  animate="visible"
                  custom={2}
                >
                  to hire a freshly baked developer.
                </motion.span>
                <br />
                <motion.span
                  variants={textVariants}
                  initial="hidden"
                  animate="visible"
                  custom={3}
                >
                  So come on...
                </motion.span>
                <br />
                <motion.span
                  variants={textVariants}
                  initial="hidden"
                  animate="visible"
                  custom={4}
                >
                  What are you waiting for?
                </motion.span>
                <br />
                <motion.span
                  variants={textVariants}
                  initial="hidden"
                  animate="visible"
                  custom={5}
                >
                  Make me{' '}
                </motion.span>
                {lastLineWords.map((word, index) => (
                  <motion.span
                    key={word}
                    variants={textVariants}
                    initial="hidden"
                    animate="visible"
                    custom={5.7 + index * 0.4}
                  >
                    {word + ' '}
                  </motion.span>
                ))}
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Home;
