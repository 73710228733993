import React, { useEffect } from 'react';
import './About.css';
import { motion } from 'framer-motion';
import faceImage from '../../assets/face.png';
import ReactGA from 'react-ga4';

const About = () => {
  useEffect(() => {
    // Adjust the path as needed to reflect the actual path used in your application
    ReactGA.send({ hitType: 'pageview', page: '/about' });
  }, []);

  return (
    <section className="about-container">
      <motion.div
        className="face-image-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2 }}
      >
        <img src={faceImage} alt="ansiktsbilden" />
      </motion.div>

      <motion.div
        className="about-text"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2 }}
      >
        <article>
          <p>
            Originally from Timrå, Sweden – born & raised. Got my High School
            diploma in Arts & Design. Around 2000, I moved to Stockholm, where I
            eventually found myself working in telecoms, focusing on Fraud &
            Revenue Assurance.
            <br />
            In 2013, I married my lovely, always-so-wise German girl in Las
            Vegas.
            <br />
            <br />
            Around 2015, we headed back north to Sundsvall, pretty close to
            Timrå. That's where we got Bruno, seriously the world’s best dog.
            Eventually, we ended up buying a house in Timrå, bringing everything
            full circle.
            <br />
            <br />
            By 2022, I decided it was time for a change, so I enrolled in a
            two-year program called "Agile Java Developer".
            <br /> Suddenly it’s 2024, and I’ve just graduated! 🥳
          </p>
        </article>
      </motion.div>
    </section>
  );
};

export default About;
