import React from 'react';
import './BackgroundLoop.css';
import videoSrc from '../../assets/backgroundloop.mp4';

const BackgroundLoop = () => {
  return (
    <div className="video-background">
      <video autoPlay loop muted playsInline className="video">
        <source
          src={videoSrc}
          autoPlay
          loop
          muted
          playsInline
          type="video/mp4"
        />
      </video>
    </div>
  );
};

export default BackgroundLoop;
