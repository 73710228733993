import axios from 'axios';

const BASE_URL = 'https://brandnewbackend-production.up.railway.app/api/blog';

const blogApi = axios.create({
  baseURL: BASE_URL,
});

// hämta inlägg
const getBlogPosts = async () => {
  try {
    // försök hämta
    const response = await blogApi.get('/getallblogposts');
    // returnera
    return response.data;
  } catch (error) {
    // felhantering
    console.error('Misslyckades med att hämta blogginlägg:', error);
    throw error;
  }
};

export { getBlogPosts };
