import React, { useEffect } from 'react';
import './Skills.css';
import { motion } from 'framer-motion';
import ReactGA from 'react-ga4';

const Category = ({ name, items }) => (
  <motion.div
    className="category-box"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
  >
    <h2 className="category-name">{name}</h2>
    <hr className="line" />
    <div className="skill-list">
      {items.map((skill, index) => (
        <motion.div
          key={skill.name}
          className="skill-item"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.05 * index, duration: 0.5 }}
        >
          {skill.name}
        </motion.div>
      ))}
    </div>
  </motion.div>
);

const Skills = () => {
  const categories = [
    {
      name: 'Programming & Development',
      items: [
        { name: 'Java' },
        { name: 'JavaScript' },
        { name: 'TypeScript' },
        { name: 'Spring Boot' },
        { name: 'Hibernate/JPA' },
        { name: 'React.js' },
        { name: 'React Native' },
        { name: 'MicroServices' },
      ],
    },
    {
      name: 'Database & API Management',
      items: [
        { name: 'MySQL' },
        { name: 'MariaDB' },
        { name: 'RESTful APIs' },
        { name: 'Java Web Services' },
      ],
    },
    {
      name: 'Frontend Technologies',
      items: [{ name: 'HTML' }, { name: 'CSS' }, { name: 'React.js' }],
    },
    {
      name: 'DevOps & Collaboration',
      items: [
        { name: 'Git' },
        { name: 'Docker' },
        { name: 'Azure DevOps' },
        { name: 'JIRA' },
        { name: 'Agile/Scrum' },
      ],
    },
    {
      name: 'Tools & Software',
      items: [
        { name: 'Visual Studio Code' },
        { name: 'IntelliJ' },
        { name: 'Eclipse' },
        { name: 'Adobe Photoshop' },
        { name: 'Adobe LightRoom' },
        { name: 'Microsoft Office' },
        { name: 'Google Analytics' },
      ],
    },
  ];

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/skills' });
  }, []);

  return (
    <motion.div
      className="skills-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="skills-description">
        Below skills vary from basic to boss-level. <br />
        Get in touch for further details!
      </div>
      <div className="categories">
        {categories.map((category, index) => (
          <Category key={index} name={category.name} items={category.items} />
        ))}
      </div>
    </motion.div>
  );
};

export default Skills;
