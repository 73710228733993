import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { sendMessage } from '../../data/newMessageApi';
import './Contact.css';
import ReactGA from 'react-ga4';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    subject: 'Website Contact',
  });

  // Initialize Google Analytics Page View tracking
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/contact' }); // Adjust the page path as necessary
  }, []);

  // Handle form data submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Pressed, handling submit', JSON.stringify(formData));
    try {
      // Send message
      const response = await sendMessage(formData);
      console.log('Response:', response);
      alert(
        'Thanks for getting in touch ' +
          response.name +
          '!' +
          ' I´ll get back to you ASAP!'
      );
      // Reset the form to default state
      setFormData({
        name: '',
        email: '',
        message: '',
        subject: 'Website Contact',
      });
    } catch (error) {
      console.log('Error sending message:', error);
      alert('Failed to send message. Please try again later.');
    }
  };

  // Handle changes in the form fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <motion.div
      className="contact-form-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <form onSubmit={handleSubmit} className="contact-form">
        <h2 className="contact-form-title">Get in touch!</h2>
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <textarea
          name="message"
          placeholder="Your Message"
          value={formData.message}
          onChange={handleChange}
          required
        />
        <button type="submit">Send Message</button>
      </form>
    </motion.div>
  );
};

export default Contact;
