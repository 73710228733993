import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home/Home';
import About from './components/about/About';
import BackgroundLoop from './components/backgroundloop/BackgroundLoop';
import Blog from './components/blog/Blog';
import Contact from './components/contact/Contact';

import Skills from './components/skills/Skills';
import Navbar from './components/navbar/Navbar';

import ReactGA from 'react-ga4';

function App() {
  ReactGA.initialize('G-SL26F3R3X8');
  return (
    <Router>
      <Navbar />
      <BackgroundLoop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;
