import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion'; 
import './Blog.css'; 
import { getBlogPosts } from '../../data/getBlogPostsApi'; 
import ReactGA from 'react-ga4';

const Blog = () => {
    const [posts, setPosts] = useState([]);
    const [error, setError] = useState(null);

    // Hämta blogginlägg och sortera dem efter datum
    useEffect(() => {
        const fetchBlogPosts = async () => {
            try {
                const data = await getBlogPosts();
                // Sortera blogginlägg efter datum, de nyaste först
                const sortedPosts = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setPosts(sortedPosts);
            } catch (error) {
                console.error('Failed to fetch blog posts:', error);
                setError('Failed to load blog posts');
            }
        };

        fetchBlogPosts();
    }, []);

    // google analytics
    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: '/blog' }); // Spåra sidvisning när komponenten monteras
    }, []);

    return (
        <motion.div
            className="blog-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2 }} 
            style={{ textAlign: 'center' }} 
        >
            {/* hämtning misslyckat meddelande */}
            {error ? <p>{error}</p> : null}
            
            {/* visa blogginlägg, annars meddelande om inga inlägg */}
            {posts.length > 0 ? (
                <div className="post-box">
                    {posts.map(post => (
                        <li key={post.id}>
                            <h2>{post.title}</h2> 
                            <p>{post.content}</p>
                            <p>Posted by Andreas on {new Date(post.createdAt).toLocaleDateString()}</p>
                            <hr style={{ borderColor: 'white', borderWidth: '1px' }} />
                        </li>
                    ))}
                </div>
            ) : !error && <p>No blog posts found.</p>}
        </motion.div>
    );
};

export default Blog;